import * as yup from "yup";
import YupPassword from "yup-password";
YupPassword(yup);

//  Auth Schema
export const verifyNumberSchema = yup.object().shape({
  mobile: yup
    .string()
    .required("Mobile Number is Required")
    .min(10, "Mobile Number must be at least 10 characters")
    .matches(
      /^\+91\d{10}$/,
      "Mobile number is not valid"
    ),
});
export const loginSchema = yup.object().shape({
  mobile: yup
    .string()
    .required("Mobile Number is Required")
    .min(10, "Mobile Number must be at least 10 characters")
    .matches(
      /^\+91\d{10}$/,
      "Mobile number is not valid"
    ),
  password: yup
    .string()
    .required("Password is Required")
    .min(
      8,
      "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "Password must contain at least 1 lower case letter")
    .minUppercase(1, "Password must contain at least 1 upper case letter")
    .minNumbers(1, "Password must contain at least 1 number")
    .minSymbols(1, "Password must contain at least 1 special character"),
});

//  Booking schema
export const createBookingSchema = yup.object().shape({
  name: yup.string().required("Customer Name is Required"),
  mobile: yup
    .string()
    .required("Mobile Number is Required")
    .min(10, "Mobile Number must be at least 10 characters")
    .matches(
      /^\+91\d{10}$/,
      "Mobile number is not valid"
    ),
  date: yup.date().required("Date is required"),
  seatsRequired: yup.object().shape({
    name: yup.string().nullable(),
    value: yup.string().required("Seats is Required"),
    data: yup.string().nullable(),
  }),
});
