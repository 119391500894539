import { createSlice } from "@reduxjs/toolkit";


const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    jwt: null,
    name: null,
    email: null,
    userData: null,
    loading: false,
    errors: null,
  },
  reducers: {
    authTriggered: (state, action) => {
      state.loading = true;
    },
    authSuccess: (state, action) => {
      state.jwt = action.payload.jwtToken;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.loading = false;
    },
    authFailure: (state, action) => {
      state.errors = action.payload;
      state.loading = false;
    },
    logoutUser: (state) => {
      state.name = null;
      state.email = null;
      state.jwt = null;
      state.loading = false;
    },
    getUserSuccess: (state, action) => {
      state.userData = action.payload;
    },
  },

});

export const {
  authTriggered,
  authSuccess,
  authFailure,
  logoutUser,
  getUserSuccess,

} = authSlice.actions;
export default authSlice.reducer;
