import React from "react";

function Button({ loading, classes, disabled, onClick, text }) {
  return (
    <button
      type="submit"
      value="submit"
      className={`primary-btn mt-0 mb-3 ${classes} ${(loading || disabled) && "disabled-btn"
        }`}
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading ? <i class="fa-solid fa-circle-notch fa-spin"></i> : text}
    </button>
  );
}

export default Button;
