import Footer from "common/footer";
import Header from "common/header/header";
import React, { useEffect } from "react";
import BookingsTable from "./layouts/BookingsListing";
import BookingsListing from "./layouts/BookingsListing";
import TextBanner from "common/textBanner";

const Profile = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <TextBanner text="Profile" />
      <BookingsListing />
      <Footer />
    </>
  );
};

export default Profile;
