import React, { useState } from "react";

// //  Stripe
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

//  Components
import CreateBooking from "./createBooking";
import PaymentForm from "./paymentForm";

const CheckoutForm = ({ initialValues = null }) => {
  return (
    <section class="checkout_area ">
      <CreateBooking />
    </section>
  );
};

export default CheckoutForm;
