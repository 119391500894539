import api from "store/api/api";

//  Get Services
export const GET_SERVICES = (_id, params) => api.get(`/service/${_id}`, { params });

//  Add Service
export const ADD_SERVICE = (data) => api.post(`/service`, data);

//  Update Service
export const UPDATE_SERVICE = (id, data) => api.put(`/service/${id}`, data);

//  Delete Service
export const DELETE_SERVICE = (id) => api.delete(`/service/${id}`);
