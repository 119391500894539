import React from "react";
import waveIcon from "include/Images/wave-blue.png";
import ButtonCustom from "common/ButtonCustom";

function ServiceCard({ name, amount, maxSeats, onClick, index }) {
  return (
    <div class="service_card_container">
      <img class="img-fluid service_icon" src={waveIcon} alt="" />
      <h6>{name}</h6>
      <h5>
        ₹ {amount?.toLocaleString()} <span>/ seat</span>
      </h5>
      <p>Seats : {maxSeats}</p>
      <a href="#!" onClick={onClick} className="custom_btn">
        Book Now
      </a>
      {/* <div class="product-details">
        <h6 className=" fs-5  fw-bold">{name}</h6>
        <div class="price">
          <h6>₹ {amount?.toLocaleString()}</h6>
          <div className="">
            <span className=" text-black  fs-6 fw-bold">Seats : </span>
            <span>{maxSeats}</span>
            
          </div>
        </div>
        <div
          class="prd-bottom d-flex justify-content-center align-content-center"
          onClick={onClick}
        >
          <a href="" class="social-info">
            <span class="lnr lnr-cart"></span>
            <p class="hover-text">Book Now</p>
          </a>
        </div>
      </div> */}
    </div>
  );
}

export default ServiceCard;
