import React from "react";
import { Button as Btn } from "react-bootstrap";

//  Components
import Button from "common/Button";

function Invoice({
  handleSubmit,
  loading,
  seats = 0,
  seatAmount = 0,
  baseAmount = 0,
  totalAmount = 0,
  cgst = 0,
  sgst = 0,
  service,
  cgstPerc = 0,
  sgstPerc = 0,
}) {
  const isLoggedIn = localStorage.getItem("user-auth-x-token");
  return (
    <div class="order_box">
      <h2>Your Order</h2>
      <ul class="list cb-list bcb-head">
        <li>
          Particular <span>Amount</span>
        </li>
      </ul>
      <ul class="list cb-list">
        <li>
          {service?.name} <span class="middle">x {seats}</span>{" "}
          <span class="last">
            ₹{" "}
            {(seatAmount)?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        </li>
        <li>
          Total Taxable Amount{" "}
          <span>₹ {isLoggedIn ? baseAmount?.toLocaleString() : 0}</span>
        </li>
        <li>
          CGST@{cgstPerc}{" "}
          <span>
            ₹{" "}
            {isLoggedIn
              ? cgst?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
              : 0}
          </span>
        </li>
        <li>
          SGST@{sgstPerc}{" "}
          <span>
            ₹{" "}
            {isLoggedIn
              ? sgst?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
              : 0}
          </span>
        </li>
      </ul>
      <ul class="list list_2 cb-list">
        <li>
          Total{" "}
          <span>
            ₹{" "}
            {isLoggedIn
              ? totalAmount?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
              : 0}
          </span>
        </li>
      </ul>
      {/* <div class="payment_item">
        <div class="radion_btn">
          <input type="radio" id="f-option5" name="selector" />
          <label for="f-option5">Check payments</label>
          <div class="check"></div>
        </div>
        <p>
          Please send a check to Store Name, Store Street, Store Town, Store
          State / County, Store Postcode.
        </p>
      </div>
      <div class="payment_item active">
        <div class="radion_btn">
          <input type="radio" id="f-option6" name="selector" />
          <label for="f-option6">Paypal </label>
          <img src="img/product/card.jpg" alt="" />
          <div class="check"></div>
        </div>
        <p>
          Pay via PayPal; you can pay with your credit card if you don’t have a
          PayPal account.
        </p>
      </div> */}
      {/* <div class="creat_account">
        <input type="checkbox" id="f-option4" name="selector" />
        <label for="f-option4">I’ve read and accept the </label>
        <a href="#">terms & conditions*</a>
      </div> */}
      <Button
        text="Book Now"
        onClick={handleSubmit}
        classes={"border-none"}
        loading={loading}
        disabled={!isLoggedIn}
      />
    </div>
  );
}

export default Invoice;
