import React, { useEffect } from "react";

//  Components
import Header from "common/header/header";
import Banner from "./layouts/banner";
import Features from "./layouts/features";
import Footer from "common/footer";
import About from "./layouts/about";
import Mission from "./layouts/mission";
import Service from "./layouts/services";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg_wrapper">
      <Header />
      <Banner />
      <div className="text-bg">
        <About />
        <Mission />
      </div>
      <Features />
      <Service />
      <Footer />
    </div>
  );
}

export default Home;
