import React, { useEffect, useState } from "react";
import $ from "jquery"; // Import jQuery
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import 'jquery-sticky';
const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const openSearchHandler = () => {
    setOpenSearch(!openSearch);
  };
  const logoutHandler = () => {
    toast.success("Logged Out Successfully");
    localStorage.removeItem("user");
    localStorage.removeItem("user-auth-x-token");
    navigate("/");
  };
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("user-auth-x-token");
  return (
    <header class={`header_area `}>
      <div class="main_menu">
        <nav class="navbar navbar-expand-lg navbar-light main_box">
          <div class="container">
            <Link to="/" className="navbar-brand logo_h">
              <svg
                fill="none"
                height="100%"
                viewBox="0 0 24 24"
                width="100%"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity={0.16}
                  d="M7.242 11.083c.449-1.674 2.17-3.394 3.843-3.843l10.434-2.796c1.673-.448 2.666.545 2.218 2.218L20.94 17.096c-.449 1.674-2.17 3.394-3.843 3.843L6.664 23.735c-1.673.448-2.666-.545-2.218-2.218l2.796-10.434Z"
                  fill="#0ce7dd"
                />
                <path
                  d="M3.06 6.9c.448-1.674 2.168-3.394 3.842-3.843L17.336.261c1.673-.448 2.667.545 2.218 2.218l-2.796 10.434c-.449 1.674-2.169 3.394-3.843 3.843L2.481 19.552C.808 20-.185 19.007.263 17.334L3.06 6.9Z"
                  fill="#0ce7dd"
                />
              </svg>
            </Link>
            <button
              class={`navbar-toggler   ${showMenu === false && "collapsed"}`}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={`${showMenu === true && "true"}`}
              aria-label="Toggle navigation"
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            >
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <div
              class={`collapse navbar-collapse offset ${showMenu === true && "show"
                } justify-content-end`}
              id="navbarSupportedContent"
            >
              <ul class="nav navbar-nav menu_nav ml-auto">
                <li class={`nav-item ${location.pathname == "/" && "active"} `}>
                  <Link class="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li
                  class={`nav-item ${location.pathname == "/contact" && "active"
                    } `}
                >
                  <Link to="/contact" class="nav-link">
                    Contact
                  </Link>
                </li>{" "}
                <li
                  class={`nav-item ${location.pathname == "/profile" && "active"
                    }  ${(location.pathname == location.pathname) == "/login"} `}
                >
                  <Link
                    to={isLoggedIn ? "/profile" : "/login"}
                    class="nav-link"
                  >
                    {isLoggedIn ? "Bookings" : "Login"}
                  </Link>
                </li>
                {isLoggedIn && (
                  <li class="nav-item">
                    <span
                      className="nav-link pointer"
                      onClick={() => {
                        logoutHandler();
                      }}
                    >
                      <i
                        class="fa-solid fa-arrow-right-from-bracket "
                        style={{ fontSize: 20 }}
                      ></i>
                    </span>
                  </li>
                )}
              </ul>
            </div>
            {/* {isLoggedIn && (
              <span class="nav-item desktop_only">
                <span
                  className="nav-link pointer"
                  onClick={() => {
                    logoutHandler();
                  }}
                >
                  <i class="fa-solid fa-arrow-right-from-bracket "></i>
                </span>
              </span>
            )} */}
          </div>
          {/* <div
              className="
             "
              onClick={() => {
                if (isLoggedIn) {
                  navigate("/profile");
                } else {
                  navigate("/login");
                }
              }}
            >
              USER
            </div> */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
