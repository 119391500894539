const Service = () => {
  return (
    <section class="services ">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 p-0">
            <div class="row services__list">
              <div class="col-lg-4 p-0 order-lg-1 col-md-4 order-md-1">
                <div class="service__item deep-bg">
                  <i class="fa-solid fa-user-tie"></i>
                  <h4 className="poppins">Expertise</h4>
                  <p className="poppins">
                    Our team comprises highly skilled and certified instructors
                    who are passionate about water sports. Their expertise
                    guarantees a safe and enjoyable experience for beginners and
                    enthusiasts alike.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 p-0 order-lg-2 col-md-4 order-md-2">
                <div class="service__item ">
                  <i class="fa-solid fa-shuffle"></i>
                  <h4 className="poppins">Variety</h4>
                  <p className="poppins">
                    From parasailing high above the azure waters to the thrill
                    of jet skiing, we offer a wide array of water sports
                    activities tailored to cater to every adventurer’s desires.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 p-0 order-lg-2 col-md-4 order-md-2">
                <div class="service__item deep-bg">
                  <i class="fa-solid fa-shield-heart"></i>
                  <h4 className="poppins">Safety First</h4>
                  <p className="poppins">
                    Your safety is our top priority. We adhere to stringent
                    safety protocols, use top-quality equipment, and conduct
                    regular maintenance checks to ensure your well-being during
                    all our activities.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 p-0 order-lg-2 col-md-4 order-md-2">
                <div class="service__item">
                  <i class="fa-regular fa-star"></i>
                  <h4 className="poppins">Customer Focus</h4>
                  <p className="poppins">
                    We believe in building lasting relationships with our
                    customers. Our friendly and knowledgeable staff are always
                    ready to assist you, answer your queries, and provide
                    guidance to make your experience truly memorable.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 p-0 order-lg-2 col-md-4 order-md-2">
                <div class="service__item deep-bg">
                  <i class="fa-solid fa-leaf"></i>
                  <h4 className="poppins">Sustainable Practices</h4>
                  <p className="poppins">
                    We are committed to preserving the natural beauty of India’s
                    coastline. Our activities are designed to minimize our
                    environmental impact, promoting responsible tourism.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 p-0 order-lg-2 col-md-4 order-md-2">
                <div class="service__item">
                  <i class="fa-solid fa-award"></i>
                  <h4 className="poppins">Convenience</h4>
                  <p className="poppins">
                    Booking your water sports adventure with us is hassle-free.
                    Our user-friendly online reservation system allows you to
                    secure your spot in advance, ensuring a seamless experience
                    on the day of your activity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
