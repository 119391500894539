// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    services: [],
    loading: false,
    errors: null,
    pagination: null,
  },
  reducers: {
    serviceTriggered: (state, action) => {
      state.loading = true;
    },
    servicesReceived: (state, action) => {
      state.services = action.payload;
      state.loading = false;
    },
    paginationReceived: (state, action) => {
      state.pagination = action.payload;
      state.loading = false;
    },
    serviceFailure: (state, action) => {
      state.errors = action.payload;
      state.loading = false;
    },
  },
});

export const { serviceTriggered, servicesReceived, serviceFailure, paginationReceived } =
  serviceSlice.actions;
export default serviceSlice.reducer;
