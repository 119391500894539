// actions.js
import api from "store/api/api";
import {
  authTriggered,
  authSuccess,
  authFailure,
  logoutUser,
  getUserSuccess,

} from "./authSlices";

import { LOGIN, REGISTER, GETUSERDETAILS, UPDATE_USER, VERIFY_PHONE_NUMBER } from "./authAPI";
//  Login
export const logIn = (credentials, callback) => async (dispatch) => {
  try {
    dispatch(authTriggered());
    const response = await LOGIN(credentials);
    if (response.status === 200) {
      dispatch(authSuccess(response.data.data));
      localStorage.setItem("user-auth-x-token", response.data.data.jwtToken);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      callback(response.data);
    } else {
      dispatch(authFailure(response.data));
      callback(response?.response?.data);
    }
  } catch (error) {
    callback(error.response?.data);
    dispatch(authFailure(error.response?.data));
  }
};
export const saveLoginData = (payLoad) => async (dispatch) => {
  dispatch(authSuccess(payLoad));
};

//  Register
// export const register = (credentials, callback) => async (dispatch) => {
//   try {
//     dispatch(authTriggered);
//     const response = await REGISTER(credentials);
//     if (response.ok === true) {
//       dispatch(authSuccess(response.data));
//       callback(response.data);
//     } else if (response.ok === false) {
//       callback(response.data);
//     }
//   } catch (error) {
//     dispatch(authFailure(error.response));
//     callback(error.response);
//   }
// };

export const getDetails = (credentials, callback) => async (dispatch) => {
  try {
    const response = await GETUSERDETAILS();
    if (response.status === 200) {
      dispatch(getUserSuccess(response.data.data));
      callback(response.data);
    } else if (response.ok === false) {
      callback(response.data);
    }
  } catch (error) {
    callback(error.response);
  }
};

export const updateUser = (data, callback) => async (dispatch) => {
  try {
    const response = await UPDATE_USER(data);
    if (response.status === 200) {
      callback(response.data);
    } else {
      callback(response.response?.data);
    }
  } catch (error) {
  }
};
//  Logout
export const logout = (callback) => async (dispatch) => {
  dispatch(logoutUser());
  localStorage.removeItem("user-auth-x-token");
  localStorage.removeItem("user");
  callback();
};

export const verifyPhoneNumber = (data, callback) => async (dispatch) => {
  try {
    const response = await VERIFY_PHONE_NUMBER(data);
    if (response.status === 200) {
      callback(response.data);
    } else {
      callback(response.response?.data);
    }
  } catch (error) {
  }
}
