import ShoppingConfirmation from "features/shop/shoppingConfirmation";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//  Routes
import {
  Checkout,
  HomePage,
  Login,
  ShopPage,
  Contact,
  Profile,
  Payment,
} from "routes";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/shop",
      element: <ShopPage />,
    },
    {
      path: "/booking-confirmation",
      element: <ShoppingConfirmation />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/checkout",
      element: <Checkout />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/payment",
      element: <Payment />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
