import Carousel from "common/slider";
import Slider from "common/slider";
import React from "react";

function Banner() {
  return (
    <section class="banner-area">
      <div class="container-fluid">
        <div class="row fullscreen align-items-center justify-content-start">
          <Carousel />
        </div>
      </div>
    </section>
  );
}

export default Banner;
