const Mission = () => {
  return (
    <div className="container text-center text_section_wrapper  max_800">
      <h1>Our Mission</h1>
      <p className=" fs-6  font_poppins">
        At Water Sports Associate, our mission is to deliver safe, exhilarating,
        and personalized water sports experiences that showcase the natural
        beauty and excitement of India’s coastal wonders. We are committed to
        ensuring the highest standards of safety, professionalism, and customer
        satisfaction in all our activities.
      </p>
    </div>
  );
};

export default Mission;
