import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import SelectSearch from "common/select";
import DatePickerComponent from "common/datepicker";

//  Redux & Validations
import { createBookingSchema } from "utils/schema";
import {
  createBooking,
  loadVendorsOnPriority,
} from "store/bookings/bookingsActions";
import { connect } from "react-redux";
import { getDetails } from "store/auth/authActions";

import TextInput from "common/TextInput";
import { Alert, Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Login from "./login";
import Invoice from "./invoice";
import { toast } from "react-toastify";
import moment from "moment";
import SectionHeading from "common/sectionHeading";

const CheckoutForm2 = ({
  initialValues = null,
  createBooking,
  getUser,
  getBookings,
  loadVendorsOnPriority,
  loadUserDetails,
}) => {
  const [loadingVendors, setLoadingVendors] = useState(false);
  const [loading, setLoading] = useState(false);
  const locationData = useLocation();
  const navigate = useNavigate();
  const service = locationData?.state?.service ?? null;
  const loc = locationData?.state?.loc ?? null;
  if (!loc || !service) navigate("/");
  const user = JSON.parse(localStorage.getItem("user"));

  const startValues = {
    name: "",
    mobile: user?.mobile ?? "",
    service: service?.name,
    seatsRequired: "",
    date: null,
  };

  useEffect(() => {}, []);
  const formik = useFormik({
    initialValues: startValues,
    enableReinitialize: true,
    validationSchema: createBookingSchema,
    onSubmit: async (values, helpers) => {
      const amount =
        service?.amount * formik?.values?.seatsRequired?.value ?? 0;
      const gstDetail = loc?.gstDetail;

      const totalAmount =
        amount +
        (amount * gstDetail?.cGstPercent) / 100 +
        (amount * gstDetail?.sGstPercent) / 100;
      setLoading(true);

      const payLoad = {
        mobile: values?.mobile,
        name: values?.name,
        totalAmount: totalAmount,
        seatsRequired: values?.seatsRequired?.value,
        service: service?.service,
        location: loc?._id,
        discount: 0,
        visitOn: parseInt(moment(values?.date).format("x")),
      };
      const InvoiceDetails = {
        seats: formik?.values?.seatsRequired?.value ?? 0,
        seatAmount: service?.amount,
        baseAmount: amount ?? 0,
        totalAmount: totalAmount ?? 0,
        cgst: (amount * (gstDetail?.cGstPercent || 0)) / 100,
        cgstPerc: gstDetail?.cGstPercent || 0,
        sgstPerc: gstDetail?.sGstPercent || 0,
        sgst: (amount * (gstDetail?.sGstPercent || 0)) / 100,
        service: service,
      };
      localStorage.setItem("bookingDetails", JSON.stringify(payLoad));
      localStorage.setItem("invoiceDetails", JSON.stringify(InvoiceDetails));

      createBooking(payLoad, (res) => {
        if (res?.code === 200) {
          // toast.success("Booking Created!");
          localStorage.setItem("bookings", JSON.stringify(res?.data));

          navigate("/payment", { state: { loc, res: res?.data } });
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      });
    },
  });

  //  Allocate vendors logic
  const vendorAllocation = (seats, vendors) => {
    if (seats && vendors?.length > 0) {
      //  Find a vendor with available seats equal or greater than seats
      return vendors.find((v) => v.seats?.availableSeats >= seats);
    } else return null;
  };

  //  Seats Listing
  const getSeatsList = (vendors) => {
    if (vendors?.length > 0) {
      let maxSeats = vendors.reduce(
        (max, v) => Math.max(max, v.seats?.availableSeats),
        0
      );
      return Array.from({ length: maxSeats }, (_, index) => index + 1);
    } else {
      return [];
    }
  };

  const vendors = service?.vendors ?? [];
  const seatsList = getSeatsList(vendors);
  const allocatedVendor = vendorAllocation(
    formik?.values?.seatsRequired?.value,
    vendors
  );
  const amount =
    service?.amount * (formik?.values?.seatsRequired?.value ?? 0) ?? 0;

  const gstDetail = loc?.gstDetail;
  const totalAmount =
    amount +
    (amount * gstDetail?.cGstPercent) / 100 +
    (amount * gstDetail?.sGstPercent) / 100;
  const isLoggedIn = localStorage.getItem("user-auth-x-token");
  return (
    <div class="container px-4">
      <SectionHeading
        heading="Complete Your Booking"
        subHeading="Provide Your Information to Proceed"
      />
      <div className="row">
        <div className="col-lg-8">
          {!isLoggedIn && (
            <Login setMobile={(val) => formik.setFieldValue("mobile", val)} />
          )}
          {isLoggedIn ? (
            <Alert variant="success" className="text-center">
              <i
                class="fa-solid fa-circle-check"
                style={{ color: "#04b532", marginRight: 10 }}
              ></i>
              You're logged in
            </Alert>
          ) : (
            <Alert variant={"info"} className="text-center">
              <i
                class="fa-solid fa-circle-info"
                style={{ color: "#0ce7dd", marginRight: 10 }}
              ></i>
              You need to enter login details before proceeding with your order!
            </Alert>
          )}
          <div
            class="billing_details"
            style={{ opacity: isLoggedIn ? 1 : 0.5 }}
          >
            <div class="row">
              <div class="col-lg-12">
                <h3>Billing Details</h3>
                <form
                  class="row contact_form"
                  action="#"
                  method="post"
                  novalidate="novalidate"
                >
                  <div class="col-md-6 form-group p_star">
                    <TextInput
                      type="text"
                      placeholder="Enter Your Name"
                      name="name"
                      isInvalid={!!(formik.touched.name && formik.errors.name)}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      value={formik.values.name}
                      error={formik.touched.name ? formik.errors.name : ""}
                      disabled={!isLoggedIn}
                    />
                  </div>
                  <div class="col-md-6 form-group p_star">
                    <DatePickerComponent
                      id="date"
                      name="date"
                      className="form-control w-100 "
                      selected={formik.values.date}
                      onChange={(date) => formik.setFieldValue("date", date)}
                      onBlur={formik.handleBlur}
                      dateFormat="dd/MM/yyyy"
                      error={formik.touched.date ? formik.errors.date : ""}
                      placeholder={"Select a Date"}
                      disabled={!isLoggedIn}
                    />
                  </div>{" "}
                  <div className="col-md-6 form-group p_star">
                    <SelectSearch
                      label="Select Seats"
                      name="seatsRequired"
                      options={seatsList?.map((s) => ({
                        name: s.toString(),
                        value: s,
                        data: s,
                      }))}
                      value={formik.values.seatsRequired}
                      error={
                        formik.touched.seatsRequired
                          ? formik.errors.seatsRequired
                          : ""
                      }
                      onChange={formik.handleChange}
                      setValue={formik.setFieldValue}
                      isDisabled={!isLoggedIn}
                    />
                    <Form.Text className="text-primary">
                      <strong>{seatsList?.length}</strong> people can be seated
                      in one{" "}
                      <strong className="capitalize">{service?.name} </strong>
                    </Form.Text>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4" style={{ opacity: isLoggedIn ? 1 : 0.5 }}>
          <Invoice
            handleSubmit={formik.handleSubmit}
            seats={formik?.values?.seatsRequired?.value ?? 0}
            seatAmount={service?.amount}
            baseAmount={amount ?? 0}
            totalAmount={totalAmount ?? 0}
            cgst={(amount * gstDetail?.cGstPercent) / 100 ?? 0}
            cgstPerc={gstDetail?.cGstPercent ?? 0}
            sgstPerc={gstDetail?.sGstPercent ?? 0}
            sgst={(amount * gstDetail?.sGstPercent) / 100 ?? 0}
            service={service}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getUser: state.auth,
  getBookings: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  createBooking: (data, callback) => dispatch(createBooking(data, callback)),
  loadVendorsOnPriority: (serviceId, callback) =>
    dispatch(loadVendorsOnPriority(serviceId, callback)),
  loadUserDetails: (params, callback) => dispatch(getDetails(params, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm2);
