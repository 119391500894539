const About = () => {
  //   const { ref } = useParallax({ speed: 10 });
  return (
    <div className="container text-center text_section_wrapper  max_800">
      <h1>About</h1>
      <p className=" fs-6  font_poppins ">
        Welcome to Water Sports Associate, your ultimate destination for
        thrilling water adventures and unforgettable experiences in the heart of
        India! Established with a passion for providing exceptional aquatic
        adventures, we take pride in being the pioneers in the water sports
        industry, dedicated to creating cherished memories for our valued
        customers.
      </p>
    </div>
  );
};

export default About;
