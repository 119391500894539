import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";

const SelectSearch = ({
  name,
  options,
  label,
  error,
  value,
  defaultValue,
  isDisabled,
  isLoading,
  onChange,
  setValue,
  ...rest
}) => {
  const customStyles = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    loadingIndicator: (styles) => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#ececff",
        color: "#000",
        cursor: "pointer",
      },
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#0ce7dd" : "white",
      borderRadius: 0,
      marginTop: 8,
      marginBottom: 8,
      padding: 10,
    }),
    menu: (base, state) => ({
      ...base,
      paddingLeft: 8,
      paddingRight: 8,
      border: 0,
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15);",
      zIndex: 9999,
    }),
    control: (base, state) => ({
      ...base,
      minHeight: 40,
      height: 40,
      paddingLeft: 8,
      color: "#333230",
      borderColor: state.isFocused
        ? error
          ? "red"
          : "#0ce7dd"
        : error
        ? "red"
        : "#E5E7EB",
      boxShadow: "0 !important",
      backgroundColor: isDisabled ? "#e9ecef" : "white",
      borderRadius: 0,
      marginBottom: 10,
      "&:hover": {
        backgroundColor: "rgba(17, 25, 39, 0.04)",
        cursor: "pointer",
      },
      "&:focus": {
        borderColor: "#0ce7dd",
      },
      borderWidth: error || state.isFocused ? 1 : 1,
    }),
  };

  return (
    <Form.Group>
      {/* <Form.Label>{label}</Form.Label> */}
      <Select
        onChange={(opt) => {
          setValue(name, opt);
        }}
        id={`select-${name}`}
        value={value}
        className="basic-single"
        classNamePrefix="select-search"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        name={name}
        options={isLoading ? [] : options}
        placeholder={label}
        isDisabled={isDisabled}
        styles={customStyles}
      />
      {error && (
        <Form.Text className="text-danger">
          {error?.value ?? error?.name}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export default SelectSearch;
