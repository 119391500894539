import Footer from "common/footer";
import Header from "common/header/header";
import TextBanner from "common/textBanner";
import React, { useEffect } from "react";
import Details from "./layouts/details";
import Members from "./layouts/members";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <TextBanner text="Contact" />
      <Details />
      <Footer />
    </>
  );
};

export default Contact;
