import React, { useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//  Redux & Validations
import { loginSchema, verifyNumberSchema } from "utils/schema";
import { logIn, verifyPhoneNumber } from "store/auth/authActions";
import { connect } from "react-redux";

//  Components
import Button from "common/Button";
import TextInput from "common/TextInput";
import SectionHeading from "common/sectionHeading";
import { auth } from "utils/firebase.config";
import Modal from "common/customModal"
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'

const LoginForm = ({ initialValues = null, logIn, getUser, verifyPhoneNumber }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const [showPass, setShowPass] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [otp, setOtp] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const phoneStartValues = {
    mobile: initialValues?.mobile ?? "+91",
  };

  const numberVerify = useFormik({
    initialValues: phoneStartValues,
    enableReinitialize: true,
    validationSchema: verifyNumberSchema,
    onSubmit: async (values, helpers) => {
      setLoading(true);
      verifyPhoneNumber(values?.mobile, (res) => {
        if (res.data.isMobileExist === false) {
          onCaptchaVerify();
          const appVerifier = window.recaptchaVerifier;
          signInWithPhoneNumber(auth, values?.mobile, appVerifier)
            .then((confirmationResult) => {
              toast.success("OTP Sent to Your Phone")
              setLoading(false);
              setModalShow(true)
              window.confirmationResult = confirmationResult;
            }).catch((error) => {
              toast.error("Something went wrong, Please try again")
              // console.log("ERROR", error)
              setLoading(false);
            });
        }
        else {
          setLoading(false);
          setShowLoginForm(true);
        }
      })
    },
  });
  const startValues = {
    mobile: numberVerify?.values?.mobile ?? "",
    password: initialValues?.password ?? "",
  };
  const formik = useFormik({
    initialValues: startValues,
    enableReinitialize: true,
    validationSchema: loginSchema,
    onSubmit: async (values, helpers) => {
      setLoading(true);
      const payLoad = {
        mobile: values?.mobile,
        password: values?.password,
      };
      logIn(payLoad, (res) => {
        if (res?.code === 200) {
          setLoading(false);
          toast.success("Login Successful!");
          navigate("/");
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      });
    },
  });
  const onCaptchaVerify = () => {
    const recaptchaContainer = document.getElementById('recaptcha-container');
    // console.log("recaptchaContainer", recaptchaContainer)
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, recaptchaContainer, {
        'size': 'invisible',
        'callback': (response) => {
        }
      }, auth);
    }


  }
  function onOTPVerify() {
    setModalShow(false);
    window.confirmationResult.confirm(otp).then(async (res) => {
      toast.success("Your Phone Number Verified")
      setShowLoginForm(true);
    }).catch((error) => {
      toast.error("Something Went wrong, Please try again")
      // console.log("error OTP", error);
    })
  }
  return (
    <section class="login_box_area login_section">
      <SectionHeading
        heading="Login/Register"
        subHeading="Login or register with your mobile number and a password"
      />
      <div class="container login-container">
        <div class="row">
          <div class="col-lg-6">
            <div class="login_box_img">
              <img class="img-fluid" src="img/authentication.png" alt="" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="login_form_inner d-flex align-items-center justify-content-center flex-column">
              {showLoginForm ? (<form
                class="row login_form"
                action="contact_process.php"
                method="post"
                id="contactForm"
                novalidate="novalidate"
                onSubmit={formik.handleSubmit}
              >
                <div class="col-md-12 form-group">
                  <TextInput
                    type="text"
                    placeholder="Mobile"
                    name="mobile"
                    // maxLength="10"
                    isInvalid={
                      !!(formik.touched.mobile && formik.errors.mobile)
                    }
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;
                      formik.handleChange(e);
                    }}
                    value={formik.values.wAter2023sports}
                    error={formik.touched.mobile ? formik.errors.mobile : ""}
                  />
                </div>
                <div class="col-md-12 form-group p_sta">
                  <TextInput
                    type={showPass ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    isInvalid={
                      !!(formik.touched.password && formik.errors.password)
                    }
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    iconClick={(e) => setShowPass((prev) => !prev)}
                    icon={
                      showPass ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                    }
                    value={formik.values.password}
                    error={
                      formik.touched.password ? formik.errors.password : ""
                    }
                  />
                </div>

                <div class="col-md-12 form-group button_login">
                  <Button class="primary-btn" loading={loading} text="Submit" />
                </div>
              </form>) : (
                <form
                  class="row login_form"
                  action="#"
                  method="post"
                  novalidate="novalidate"
                  onSubmit={numberVerify.handleSubmit}
                >
                  <div class="col-md-12 form-group">
                    <TextInput
                      type="text"
                      placeholder="Mobile"
                      name="mobile"
                      // maxLength="10"
                      isInvalid={!!(numberVerify.touched.mobile && numberVerify.errors.mobile)}
                      onBlur={numberVerify.handleBlur}
                      onChange={(e) => {
                        if (isNaN(e.target.value)) return;
                        numberVerify.handleChange(e);
                      }}
                      // disabled={isLoggedIn}
                      value={numberVerify.values.mobile}
                      error={numberVerify.touched.mobile ? numberVerify.errors.mobile : ""}
                    />

                  </div>
                  <div class="col-md-12 form-group button_login">
                    <Button loading={loading} text="Verify Phone Number"></Button>
                  </div>
                </form>)}

            </div>
          </div>
        </div>

      </div>
      <Modal
        setOtp={setOtp}
        otp={otp}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onOTPVerify={onOTPVerify}
      >

      </Modal>
      <div id="recaptcha-container"></div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  getUser: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  logIn: (data, callback) => dispatch(logIn(data, callback)),
  verifyPhoneNumber: (data, callback) => dispatch(verifyPhoneNumber(data, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
